// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// compat
import getScrollTop from "../../core/compatibility/get-scroll-top"

// <Sticky />
type Props = {
  /** margin from top of page */
  margin: number,
  /** Content */
  children: any,
  /** Custom class for root element */
  className?: string,
  ...
}

/**
 * Sticky
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .sticky           | Root element                                           |
 * |------------------|--------------------------------------------------------|
 */
class Sticky extends React.Component<Props> {
  static defaultProps = {
    margin: 0,
  }

  // trackers
  eventListeners: { [eventName: string]: Function } = {}

  // refs
  stickyElement: ?HTMLElement

  stick(event: Event) {
    const { margin } = this.props
    const { stickyElement } = this
    const { parentElement } = stickyElement

    const scrollTop = getScrollTop()
    const stickyRect = stickyElement.getBoundingClientRect()
    const parentRect = parentElement.getBoundingClientRect()

    const parentWidth = parentElement.offsetWidth
    const parentTop = scrollTop + parentRect.top

    const minOffset = 0
    const maxOffset = parentRect.height - stickyRect.height

    let offset = scrollTop - parentTop + margin

    if (offset < minOffset) {
      stickyElement.style.position = ""
      stickyElement.style.transform = `none`
      stickyElement.style.width = ""
    } else if (offset >= maxOffset) {
      stickyElement.style.position = ""
      stickyElement.style.transform = `translate3d(0px, ${maxOffset}px, 0px)`
      stickyElement.style.width = ""
    } else {
      stickyElement.style.position = "fixed"
      stickyElement.style.transform = `none`
      stickyElement.style.width = `${parentWidth}px`
      stickyElement.style.left = parentRect.left
      stickyElement.style.top = `${margin}px`
    }
  }

  componentDidMount() {
    // set up handler
    let handler = this.stick.bind(this)

    // define event listeners
    this.eventListeners = {
      scroll: handler,
      resize: handler,
      "routeUpdate.gatsby": handler,
    }

    // apply event listeners to window
    Object.keys(this.eventListeners).forEach((eventName) =>
      window.addEventListener(eventName, this.eventListeners[eventName])
    )
  }

  componentWillUnmount() {
    // remove event listeners from window
    Object.keys(this.eventListeners).forEach((eventName) =>
      window.removeEventListener(eventName, this.eventListeners[eventName])
    )
    this.eventListeners = {}
  }

  // react methods

  render() {
    let { margin, children, className, ...stickyProps } = this.props

    return (
      <div
        ref={(stickyElement) => (this.stickyElement = stickyElement)}
        {...stickyProps}
        {...classNames("sticky").plus(className)}
      >
        {children}
      </div>
    )
  }
}

/**
 * Exports
 */
export default Sticky
