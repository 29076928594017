import React from "react"
import Accordian from "../../core/components/accordian"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import ContactUs from "../../client/compositions/contact-us"
import Row from "../../core/components/row"
import Sticky from "../../core/components/sticky"
import Tagline from "../../client/compositions/tagline"
import { email, telephone } from "../../client/elements/contact-details"
import { quickMenu } from "../../client/elements/menus"

/**
 * FAQ Page
 * @see https://zpl.io/29Q5MNW
 */
function FAQPage() {
  return (
    <article id="page-faq" className="page-content">
      {quickMenu}

      <Banner
        title={
          <>
            How can
            <br />
            we help you?
          </>
        }
      >
        <DecorationFluid image="faq/banner-01.small.jpg" />
        <DecorationFluid image="faq/banner-02.small.jpg" />
        <DecorationFluid image="faq/banner-03.small.jpg" />
        <DecorationFluid image="faq/banner-04.small.jpg" />
      </Banner>

      <Row id="faq" className="long-text">
        <Block className="block-content">
          <Sticky margin={40}>
            <h2 className="title">Contact support</h2>
            <div className="content">
              <p>Call us {telephone}</p> <p>Or write an email to {email}</p>
            </div>
          </Sticky>
        </Block>
        <Block className="block-content">
          <Accordian
            label={
              <>
                <Decoration image="icons/accordian-open.svg" className="open" />
                <Decoration
                  image="icons/accordian-close.svg"
                  className="close"
                />
                <h4 className="title">How can I place an order?</h4>
              </>
            }
          >
            You can make an order through our website by{" "}
            <Anchor to="/contact">filling out a submission form</Anchor>, or by
            calling us directly at {telephone}.
          </Accordian>
          <Accordian
            label={
              <>
                <Decoration image="icons/accordian-open.svg" className="open" />
                <Decoration
                  image="icons/accordian-close.svg"
                  className="close"
                />
                <h4 className="title">Do you have an online catalogue?</h4>
              </>
            }
          >
            Yes, you can view it <Anchor to="/resources">here</Anchor>.
          </Accordian>
          <Accordian
            label={
              <>
                <Decoration image="icons/accordian-open.svg" className="open" />
                <Decoration
                  image="icons/accordian-close.svg"
                  className="close"
                />
                <h4 className="title">
                  Can I check the status of an order I made directly through
                  InVinity?
                </h4>
              </>
            }
          >
            Yes, you can call us at {telephone} Monday through Friday from
            9am-5pm EST to inquire about any order made directly through us.
          </Accordian>
        </Block>
      </Row>

      <ContactUs>
        <DecorationFluid image="/faq/contact.small.jpg" />
      </ContactUs>
      <Tagline />
    </article>
  )
}

export default FAQPage
