import React from "react"
import Anchor from "../../core/components/anchor"
import Block from "../../core/components/block"
import Row from "../../core/components/row"

/** Contact us component */
function ContactUsComponent(props) {
  return (
    <Row id="contact-us" className="contact-us">
      <Block className="block-media">{props.children}</Block>
      <Block className="block-content">
        <h3 className="title">Contact Us</h3>
        <div className="content">
          Please <Anchor to="/contact">contact us</Anchor> - we are ready to
          help make your vision a reality, wherever you are in your wine
          showcasing journey.
        </div>
      </Block>
    </Row>
  )
}

/**
 * Compose
 */
const ContactUs = ContactUsComponent

export default ContactUs
