// @flow

// react
import * as React from "react"
import classNames from "react-css-module-classnames"

// utility
import guid from "../../core/functions/guid"

// <Accordian />
type Props = {
  /** Whether to show expanded by default */
  defaultExpanded?: boolean,
  /** Accordian control label */
  label: any,
  /** Content */
  children: any,
  /** Custom class for root element */
  className?: string,
  ...
}

type State = {
  expanded: boolean | null,
}

/**
 * Accordian
 *
 * ## CSS Classes
 * |------------------|--------------------------------------------------------|
 * | class            | Purpose                                                |
 * |------------------|--------------------------------------------------------|
 * | .accordian       | Root element                                           |
 * |------------------|--------------------------------------------------------|
 */
class Accordian extends React.Component<Props, State> {
  static defaultProps = {
    defaultExpanded: false,
  }

  state = {
    expanded: null,
  }

  // refs

  labelElement: ?HTMLButtonElement
  contentElement: ?HTMLDivElement

  // custom methods
  toggleState() {
    const { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  // react methods

  componentDidMount() {
    const { defaultExpanded } = this.props
    this.setState({ expanded: defaultExpanded })
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { expanded: prevExpanded } = prevState
    const { expanded } = this.state
    const { contentElement } = this

    // initial setup
    contentElement.style.display = expanded ? "block" : "none"

    // animate
    // @todo
    if (prevExpanded !== null && expanded !== prevExpanded) {
      contentElement.style.display = expanded ? "block" : "none"
    }
  }

  render() {
    const {
      defaultExpanded,
      label,
      children,
      className,
      ...accordianProps
    } = this.props
    const { expanded } = this.state

    const id = guid()

    return (
      <div {...accordianProps} {...classNames("accordian").plus(className)}>
        <button
          ref={(labelElement) => (this.labelElement = labelElement)}
          aria-controls={`content-${id}`}
          aria-expanded={expanded}
          onClick={this.toggleState.bind(this)}
          {...classNames("control")}
        >
          {label}
        </button>
        <div
          ref={(contentElement) => (this.contentElement = contentElement)}
          id={`content-${id}`}
          aria-hidden={!expanded}
          {...classNames("content")}
        >
          {children}
        </div>
      </div>
    )
  }
}

/**
 * Exports
 */
export default Accordian
